/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "../variables";

.mdl-card {
  display: flex;
  flex-direction: column;
  font-size: $card-font-size;
  font-weight: 400;
  min-height: $card-height;
  overflow: hidden;
  width: $card-width;
  z-index: $card-z-index;
  position: relative;
  background: $card-background-color;
  border-radius: 2px;
  box-sizing: border-box;
}

.mdl-card__media {
  background-color: $card-image-placeholder-color;
  background-repeat: repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-origin: padding-box;
  background-attachment: scroll;
  box-sizing: border-box;
}

.mdl-card__title {
  align-items: center;
  color: $card-text-color;
  display: block;
  display: flex;
  justify-content: stretch;
  line-height: normal;
  padding: $card-vertical-padding $card-horizontal-padding;
  perspective-origin: $card-title-perspective-origin-x $card-title-perspective-origin-y;
  transform-origin: $card-title-transform-origin-x $card-title-transform-origin-y;
  box-sizing: border-box;

  &.mdl-card--border {
    border-bottom: 1px solid $card-border-color;
  }
}

.mdl-card__title-text {
  align-self: flex-end;
  color: inherit;
  display: block;
  display: flex;
  font-size: $card-title-font-size;
  font-weight: $card-title-text-font-weight;
  line-height: normal;
  overflow: hidden;
  transform-origin: $card-title-text-transform-origin-x $card-title-text-transform-origin-y;
  margin: 0;
}

.mdl-card__subtitle-text {
  font-size: $card-subtitle-font-size;
  color: $card-subtitle-color;
  margin: 0;
}

.mdl-card__supporting-text {
  color: $card-supporting-text-text-color;
  background-color: $card-supporting-text-background-color;
  font-size: $card-supporting-text-font-size;
  line-height: $card-supporting-text-line-height;
  overflow: hidden;
  padding: $card-vertical-padding $card-horizontal-padding;
  box-sizing: border-box;
}

.mdl-card__actions {
  font-size: $card-actions-font-size;
  line-height: normal;
  width: 100%;
  //background-color: rgba(0,0,0,0);
  background-color: $card-action-background-color;
  padding: 8px;
  box-sizing: border-box;

  &.mdl-card--border {
    border-top: 1px solid $card-border-color;
  }
}

.mdl-card--expand {
  flex-grow: 1;
}


.mdl-card__menu {
  position: absolute;
  right: 16px;
  top: 16px;
}
