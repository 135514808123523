/**
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

@import "../variables";
@import "../mixins";

.mdl-shadow--0dp {
  @include shadow-0dp();
}

.mdl-shadow--1dp {
  @include shadow-1dp();
}

.mdl-shadow--2dp {
  @include shadow-2dp();
}

.mdl-shadow--3dp {
  @include shadow-3dp();
}

.mdl-shadow--4dp {
  @include shadow-4dp();
}

.mdl-shadow--5dp {
  @include shadow-5dp();
}

.mdl-shadow--6dp {
  @include shadow-6dp();
}

.mdl-shadow--7dp {
  @include shadow-7dp();
}

.mdl-shadow--8dp {
  @include shadow-8dp();
}

.mdl-shadow--9dp {
  @include shadow-9dp();
}

.mdl-shadow--10dp {
  @include shadow-10dp();
}

.mdl-shadow--11dp {
  @include shadow-11dp();
}

.mdl-shadow--12dp {
  @include shadow-12dp();
}

.mdl-shadow--13dp {
  @include shadow-13dp();
}

.mdl-shadow--14dp {
  @include shadow-14dp();
}

.mdl-shadow--15dp {
  @include shadow-15dp();
}

.mdl-shadow--16dp {
  @include shadow-16dp();
}

.mdl-shadow--17dp {
  @include shadow-17dp();
}

.mdl-shadow--18dp {
  @include shadow-18dp();
}

.mdl-shadow--19dp {
  @include shadow-19dp();
}

.mdl-shadow--20dp {
  @include shadow-20dp();
}

.mdl-shadow--21dp {
  @include shadow-21dp();
}

.mdl-shadow--22dp {
  @include shadow-22dp();
}

.mdl-shadow--23dp {
  @include shadow-23dp();
}

.mdl-shadow--24dp {
  @include shadow-24dp();
}
